import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import TutorialsArea from "../components/PageCode/TutorialsArea";
import PostsArea from "../components/PageCode/PostsArea";
import MainTagList from "../components/PageMainIndex/MainTagList";

export const pageQuery = graphql`
  query {
    allTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/([a-z])+//" } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        nodes {
          fields {
            slug
          }
        }
      }
    }

    allTutorials: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//content/data/([a-z])+/tutorials//" }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 16
    ) {
      edges {
        node {
          fileAbsolutePath
          fields {
            slug
          }
          frontmatter {
            title
            tutorial
            date(locale: "ko", formatString: "YYYY년 MM월 DD일")
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
          }
          excerpt(pruneLength: 50)
        }
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//content/data/([a-z])+/posts//" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 16
    ) {
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            date(locale: "ko", formatString: "YYYY년 MM월 DD일")
            title
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MainIndex = ({ location, data }) => {
  return (
    <Layout location={location} bgColor={"#F3F2F2"}>
      <SEO
        title="프로그래밍 디자인 콘텐츠"
        keywords={[`JavaScript`, `Programming`, `Coding`, `React`]}
      />

      <main role="main" style={{ marginTop: "56px" }}>
        <MainTagList list={data.allTags.group} />
        <TutorialsArea tutorials={data.allTutorials.edges} />
        <PostsArea posts={data.allPosts.edges} title={"Recent Posts"} />
      </main>
    </Layout>
  );
};

export default MainIndex;
