import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "./MainTagList.module.scss";
import bigTags from "../../../content/bigTagList.json";
import MainTag from "./MainTag";
import BigTag from "../Common/BigTag";

const cx = classNames.bind(styles);

const MainTagList = ({ list = [] }) => {
  return (
    <div className={cx("mainTagList")}>
      <Container>
        <Row>
          <Col className={cx("mainTagColumn")}>
            {list
              .sort((a, b) => b.totalCount - a.totalCount)
              .map(({ fieldValue, nodes }) => {
                const menu = nodes[0].fields.slug.split("/")[1];
                const result = bigTags[menu].find(
                  entry => entry.url === fieldValue.toLowerCase()
                );
                return (
                  result && (
                    <BigTag
                      key={fieldValue}
                      to={`/${menu}/tags/${result.url}`}
                      color={result.color}
                    >
                      {result.name}
                    </BigTag>
                  )
                );
              })
              .slice(0, 10)}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MainTagList;
