import React from "react";
import { Link } from "gatsby";
import classNames from "classnames/bind";
import styles from "./MainTag.module.scss";

const cx = classNames.bind(styles);

const MainTag = ({ to, children }) => {
  return (
    <Link to={to} className={cx("mainTag")}>
      {children}
      <div className={cx("mainTagUnderline")} />
    </Link>
  );
};

export default MainTag;
